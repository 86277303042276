import styled from 'styled-components';
import { allBreakpointValue, font, mediaBreakpointUp } from '@/style/mixins';
import { Card1Tag, Card1More, Container as Card1 } from '@/components/blocks/Card1/Card1.styled';

export const Container = styled.div`
    z-index: 0;
    display: flex;
    width: 100%;

    ${Card1} {
        width: 100%;
        padding: 14px;
        ${mediaBreakpointUp('xxl')} {
            padding: 16px;
        }

        ${mediaBreakpointUp('fhd')} {
            padding: 20px;
        }

        & > h3 {
            margin-right: 0;
        }

        & > p {
            ${font('text1-b')};
            width: auto;
            ${allBreakpointValue(' max-width', 279, 200, 177)};

            & > p {
                width: 100%;
            }
        }

        &.simple-widget > p {
            max-width: none;
        }
    }

    ${Card1More} {
        margin-top: auto;
        margin-left: 0px;
        height: auto;
        padding: 0;
    }

    ${Card1Tag} {
        flex-shrink: 0;
        top: 14px;
        right: 14px;
        ${mediaBreakpointUp('xxl')} {
            top: 16px;
            right: 16px;
        }

        ${mediaBreakpointUp('fhd')} {
            top: 20px;
            right: 20px;
        }
    }
`;
