import styled from 'styled-components';
import Card1 from '@/components/blocks/Card1/Card1';
import { Card1Icon } from '@/components/blocks/Card1/Card1.styled';
import { mediaBreakpointUp, vw } from '@/style/mixins';

export const Card1Wrapper = styled(Card1)`
    h3 {
        margin-right: 0;
        width: ${(206 / 286) * 100}%;

        ${mediaBreakpointUp('md')} {
            width: ${(397 / 595) * 100}%;
        }
    }

    p {
        margin-right: 0;
        width: ${(251 / 397) * 100}%;

        ${mediaBreakpointUp('xl')} {
            width: ${(352 / 595) * 100}%;
        }
    }

    ${Card1Icon} {
        width: 16px;
        height: 16px;

        ${mediaBreakpointUp('xxl')} {
            width: 18px;
            height: 18px;
        }

        ${mediaBreakpointUp('fhd')} {
            width: ${vw(24)};
            height: ${vw(24)};
        }
    }
`;
