import styled from 'styled-components';
import { allBreakpointValue, color, font, hover, mediaBreakpointUp, offset, vw } from '@/style/mixins';

export const Container = styled.div`
    position: absolute;
    left: ${vw(-40)};
    will-change: left;
    background-color: ${color('white')};
    display: flex;
    border-radius: 0 0 8px 8px;
    padding: ${vw(40)};

    transition-property: opacity, visibility;
    transition-timing-function: ease-in-out;
    transition-duration: 0.2s;

    opacity: 0;
    visibility: hidden;

    ${allBreakpointValue('top', 53, 39, 34)};

    ${allBreakpointValue('width', 930, 695, 631)};

    ._is-ie & {
        ${mediaBreakpointUp('xl')} {
            max-width: 631px;
        }
        ${mediaBreakpointUp('xxl')} {
            max-width: 695px;
        }

        ${mediaBreakpointUp('fhd')} {
            max-width: 930px;
        }
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: -50%;
        z-index: -1;
        height: 100%;
    }

    & > ul {
        margin: 0;
        padding: 0;
        ${offset('l', 'margin-right')};

        & > li {
            ${font('text1-b')};

            &:not(:last-child) {
                margin-bottom: ${vw(16)};
            }
        }
    }

    li {
        list-style: none;
        color: ${color('blue')};

        & > a,
        p {
            color: ${color('black')};
            ${hover(`color: ${color('blue')}`)};
        }
    }
`;
