import styled from 'styled-components';
import { allBreakpointValue, color, font, hover, mediaBreakpointUp, vw } from '@/style/mixins';
import Widget from '@/components/blocks/Widget/Widget';

export const Container = styled.ul`
    ${font('menu')};
    display: flex;
    align-items: center;
    color: currentColor;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;

    & > li {
        list-style: none;
        cursor: pointer;
        position: relative;

        &:not(:last-child) {
            ${allBreakpointValue('margin-right', 36, 30, 26)};
        }

        span {
            white-space: nowrap;
            transition: color 0.3s ease-in-out;
        }

        & > span {
            &.active {
                color: ${color('blue')};
            }
        }

        ${hover(`
            span {
                color: ${color('blue')}
            }
        `)};
    }
`;

export const HeaderInnerSubLinks = styled.ul`
    ${font('text2-b')};
    margin: ${vw(16)} 0 0 20px;
    padding: 0;

    li {
        &:not(:last-child) {
            margin-bottom: ${vw(10)};
        }
    }
`;

export const HeaderBanner = styled(Widget)`
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;

    margin-left: auto;

    ${mediaBreakpointUp('xl')} {
        width: ${vw(280, 1280)};
        min-height: ${vw(185, 1280)};
    }

    ${mediaBreakpointUp('xxl')} {
        width: ${vw(315, 1440)};
        min-height: ${vw(208, 1440)};
    }

    ${mediaBreakpointUp('fhd')} {
        width: ${vw(420, 1920)};
        min-height: ${vw(278, 1920)};
    }

    ._is-ie & {
        max-width: 556px;
    }
`;
