import React, { memo, useEffect } from 'react';
import { Container } from './Widget.styled';
import Card1, { Card1Props } from '@/components/blocks/Card1/Card1';
import CardApp, { CardAppProps } from '@/components/blocks/CardApp/CardApp';
import { IMetrika } from '@/interfaces';
import { clearFromNBSP, sendMetrik } from '@/helpers';
import { useInView } from 'react-intersection-observer';

export interface WidgetProps extends IMetrika {
    id: string;
    className?: string;
    card: Card1Props;
    appLink?: {
        apple: string;
        google: string;
    };
}

const Widget: React.FC<WidgetProps> = (props) => {
    const { ref, inView } = useInView({ triggerOnce: true });
    useEffect(() => {
        if (inView && props.metrikaAdv) {
            sendMetrik(`banners-impressions-${props?.id}`, 'banners', { 'banner-id-impression': clearFromNBSP(props?.id) }, 'impressions');
        }
    }, [inView]);

    const submitMetriks = (e: React.MouseEvent) => {
        e.stopPropagation();
        sendMetrik(`banners-clicks-${props?.id}`, 'banners', { 'banner-id-click': props?.id }, 'clicks');
    };
    return (
        <Container className={props.className} onClick={submitMetriks} ref={ref}>
            {'appLink' in props ? (
                <CardApp {...(props as CardAppProps)} isHover={false} />
            ) : (
                <Card1 linkBg={false} {...props.card} isHover={false} />
            )}
        </Container>
    );
};

export default memo(Widget);
