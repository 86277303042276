import React, { memo } from 'react';
import dynamic from 'next/dynamic';
import { MenuILink } from '@/interfaces';
import PotentiallyLink from '@/components/common/PotentiallyLink/PotentiallyLink';
import { HeaderInnerSubLinks } from '@/components/blocks/HeaderLinks/HeaderLinks.styled';
import { sendMetrik } from '@/helpers';

const Spoiler = dynamic(() => import('@/components/blocks/Spoiler/Spoiler'));

interface HeaderLinksInnerT extends Pick<MenuILink, 'subLinks'> {
    currInnerSM: null | string;
    setCurrInnerSM: React.Dispatch<React.SetStateAction<null | string>>;
    firstMenuValue: string;
}
const HeaderLinksInner = ({ subLinks, currInnerSM, setCurrInnerSM, firstMenuValue }: HeaderLinksInnerT) => {
    return (
        <ul>
            {subLinks?.map((item, index) => (
                <li
                    key={index}
                    onClick={(e) => {
                        e.stopPropagation();
                        sendMetrik('menu-click-category', 'menu', {
                            'menu-1lvl': firstMenuValue,
                            'menu-2lvl': item.text
                        });
                    }}
                >
                    {item.subLinks ? (
                        <Spoiler
                            key={index}
                            header={<p> {item.text} </p>}
                            headerBtn={false}
                            id={`${item.text}${index}`}
                            onClickFunc={setCurrInnerSM}
                            outsideIsOpen={currInnerSM === `${item.text}${index}`}
                        >
                            <HeaderInnerSubLinks>
                                {item.subLinks.map(({ href, text }, i) => (
                                    <li
                                        key={i}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            sendMetrik('menu-click-category', 'menu', {
                                                'menu-1lvl': firstMenuValue,
                                                'menu-2lvl': item.text,
                                                'menu-3lvl': text
                                            });
                                        }}
                                    >
                                        {PotentiallyLink({ href, text, i })}
                                    </li>
                                ))}
                            </HeaderInnerSubLinks>
                        </Spoiler>
                    ) : (
                        PotentiallyLink(item)
                    )}
                </li>
            ))}
        </ul>
    );
};

export default memo(HeaderLinksInner);
