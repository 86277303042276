import React, { useEffect, useState } from 'react';
import { detect } from 'detect-browser';
import { Card1Props } from '@/components/blocks/Card1/Card1';
import { Card1Wrapper } from '@/components/blocks/CardApp/CardApp.styled';

export interface CardAppProps {
    card: Omit<Card1Props, 'link'>;
    appLink: {
        apple: string;
        google: string;
    };
    isHover?: boolean;
}

const CardApp: React.FC<CardAppProps> = ({ card, appLink: { apple, google }, isHover }) => {
    const [href, setHref] = useState(google);

    useEffect(() => {
        setHref(['iOS', 'Mac OS'].includes(detect()?.os || '') ? apple : google);
    }, []);

    return (
        <Card1Wrapper
            {...card}
            link={{ href: href, text: '', isForeign: true }}
            iconNames={['apple', 'googleplay']}
            linkMore={false}
            isHover={isHover}
        />
    );
};

export default CardApp;
