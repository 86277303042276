import React, { useRef, useLayoutEffect, useState } from 'react';
import useDebounce from '@/hooks/useDebounce';
import { Container } from './HeaderSubLink.styled';

interface HeaderSubLinkProps {
    isShowSubMenu: boolean;
    activeId: number;
    index: number;
    offsetSubMenu: number;
    shift?: number;
}

const HeaderSubLink: React.FC<HeaderSubLinkProps> = ({ isShowSubMenu, activeId, index, offsetSubMenu, children, shift = 3 }) => {
    const refSubLink = useRef<HTMLDivElement | null>(null);
    const [left, setLeft] = useState('');
    const leftDebounced = useDebounce(left, 50);

    useLayoutEffect(() => {
        const element = refSubLink.current;

        if (element instanceof HTMLDivElement && window) {
            const { x, width } = element.getBoundingClientRect();

            const rightSide = Number((x + width + shift).toFixed(0));
            const { innerWidth } = window;

            if (rightSide > innerWidth) {
                const section = rightSide - innerWidth;
                const leftValue = parseFloat(leftDebounced);

                setLeft(`-${((section + Math.abs(shift)) / innerWidth) * 100 - (isNaN(leftValue) ? 0 : leftValue)}vw`);
            }
        }
    });

    return (
        <Container
            ref={refSubLink}
            style={{
                opacity: `${isShowSubMenu && activeId === index ? 1 : 0}`,
                visibility: `${isShowSubMenu && activeId === index ? 'visible' : 'hidden'}`,
                transitionDuration: `${isShowSubMenu && activeId === index ? '.4s' : '.2s'}`,
                top: `${offsetSubMenu}px`,
                left: leftDebounced
            }}
        >
            {children}
        </Container>
    );
};

export default HeaderSubLink;
