import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container, HeaderBanner } from './HeaderLinks.styled';
import PotentiallyLink from '@/components/common/PotentiallyLink/PotentiallyLink';
import { MenuILink } from '@/interfaces';
import { WidgetProps } from '@/components/blocks/Widget/Widget';
import ResizeObserver from 'resize-observer-polyfill';
import HeaderLinksInner from '@/components/blocks/HeaderLinks/HeaderLinksInner';
import HeaderSubLink from '@/components/blocks/HeaderSubLink/HeaderSubLink';
import { sendMetrik } from '@/helpers';
import { useRouter } from 'next/router';

interface HeaderLinksProps {
    parent: HTMLDivElement | null;
    menu: MenuILink[];
    layer: HTMLDivElement | null;
    widgets?: WidgetProps[] | null;
}

const getOffsetSubmenu = (header: HTMLDivElement, menu: HTMLUListElement) => header.clientHeight - menu.getBoundingClientRect().top;

const HeaderLinks: React.FC<HeaderLinksProps> = ({ parent, menu, layer, widgets }) => {
    const refMenu = useRef<HTMLUListElement | null>(null);
    const [activeId, setActiveId] = useState(-1);
    const [offsetSubMenu, seOffsetSubMenu] = useState(0);
    const [isShowSubMenu, setIsShowSubMenu] = useState(false);
    const [shownSubmenus, setShownSubmenus] = useState<number[]>([]);
    const [currInnerSM, setCurrInnerSM] = useState<string | null>(null);
    const { pathname } = useRouter();

    useEffect(() => {
        if (!shownSubmenus.includes(activeId)) {
            setShownSubmenus((prev) => [...prev, activeId]);
            if (menu[activeId]?.id)
                sendMetrik(
                    `banners-impressions-${menu[activeId]?.id}`,
                    'banners',
                    { 'banner-id-impression': menu[activeId]?.id },
                    'impressions'
                );
        }
    }, [activeId]);

    const hideSubMenu = useCallback((e) => {
        e.stopPropagation();
        setIsShowSubMenu(false);
        setCurrInnerSM(null);
    }, []);

    useEffect(() => {
        if (!refMenu.current) return;
        const menu = refMenu.current;

        if (isShowSubMenu) {
            menu.addEventListener('mouseleave', hideSubMenu);
            layer?.addEventListener('mousemove', hideSubMenu);
        }
        parent?.classList[isShowSubMenu ? 'add' : 'remove']('_subm-show');

        return () => {
            menu.removeEventListener('mouseleave', hideSubMenu);
            layer?.removeEventListener('mousemove', hideSubMenu);
            parent?.classList.remove('_subm-show');
        };
    }, [isShowSubMenu, refMenu, hideSubMenu, layer, parent]);

    useEffect(() => {
        let resizeObserver: ResizeObserver;
        if (parent && refMenu.current) {
            const resizeHandler = () => {
                if (parent && refMenu.current) seOffsetSubMenu(getOffsetSubmenu(parent, refMenu.current));
            };
            resizeObserver = new ResizeObserver(resizeHandler);
            resizeObserver?.observe(parent);
            resizeHandler();
        }

        return () => resizeObserver?.disconnect();
    }, [parent, refMenu]);

    useEffect(() => {
        if (!refMenu.current) return;
        const menu = refMenu.current;

        const handler = (e: Event) => {
            e.stopPropagation();
            e.stopPropagation();
            const inx = (e.target as HTMLElement)?.getAttribute('data-indx');
            if (inx !== null) {
                setActiveId(+inx);
                setIsShowSubMenu(true);
                return;
            }

            if (
                (e.target instanceof HTMLAnchorElement && e.target.parentElement instanceof HTMLSpanElement) ||
                e.target instanceof HTMLSpanElement
            ) {
                setIsShowSubMenu(false);
            }
        };

        menu.addEventListener('mouseover', handler);

        return () => menu.removeEventListener('mouseover', handler);
    }, [setIsShowSubMenu]);

    useEffect(() => {
        layer?.classList[isShowSubMenu ? 'add' : 'remove']('_show');
    }, [isShowSubMenu, layer]);

    return (
        <Container ref={refMenu}>
            {menu?.map((item, index) => (
                <li
                    key={index}
                    onClick={() => {
                        sendMetrik('menu-click-category', 'menu', { 'menu-1lvl': item.text });
                    }}
                >
                    <span data-indx={!!item.subLinks ? index : null} className={pathname === item.href ? 'active' : undefined}>
                        {PotentiallyLink({
                            text: item.text,
                            href: !item.subLinks ? item.href : undefined
                        })}
                    </span>

                    {item.id && (
                        <HeaderSubLink isShowSubMenu={isShowSubMenu} activeId={activeId} index={index} offsetSubMenu={offsetSubMenu}>
                            {item.subLinks && (
                                <HeaderLinksInner
                                    subLinks={item.subLinks}
                                    currInnerSM={currInnerSM}
                                    setCurrInnerSM={setCurrInnerSM}
                                    firstMenuValue={item.text}
                                />
                            )}
                            {widgets && !!widgets?.length && (
                                <HeaderBanner {...widgets.filter((widget) => widget.id === menu[index].id)[0]} />
                            )}
                        </HeaderSubLink>
                    )}
                </li>
            ))}
        </Container>
    );
};

export default HeaderLinks;
